export const backend_url = "https://www.soleilnoir.kr:6060";
// http://localhost:6060
// http://192.168.0.137:6060
// http://172.30.1.60:6060
// https://www.soleilnoir.kr:6060

export const screen_width_data = {
  sm: 613,
  md: 860,
  lg: 1280,
};